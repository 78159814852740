import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "title": "Edit Steriliser" } }, [_c("text-input", { key: "description", attrs: { "label": "Steriliser Name", "placeholder": "Type Steriliser Name", "max-length": 30, "form-item": "", "rules": "required", "span": 24 } }), _c("select-input", { key: "steriliserTypeId", attrs: { "reference": "sterilization.common.steriliser-types", "source": "id", "source-label": "name", "label": "Type", "span": 12 } }), _c("select-input", { key: "methodId", attrs: { "reference": "sterilization.common.methods", "source": "id", "source-label": "name", "label": "Method", "span": 12 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditSteriliser"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditSteriliser = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-steriliser" }, [_c("resource", { attrs: { "name": "sterilization.sterilisers", "api-url": _vm.apiUrl, "create": _vm.EditSteriliser, "redirect-route": "/sterilization-audits/steriliser" } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditSteriliser",
  data() {
    return {
      EditSteriliser,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
